import moment from "moment";
import React from "react";
import DateFormatter from "../../../../Layouts/date-formatter/date-formatter";
import {
  STATUS_HISTORY_FAILURE,
  STATUS_HISTORY_SUCCESS,
} from "../../../constants";

export const labelPrefix = "label.individualplayer.statusHistory.";

const initialState = {
  loading: true,
  data: null,
  columns: [
    {
      field: "customerAccountUpdateDate",
      title: labelPrefix + "customerAccountUpdateDate",
      render: (rowData) => (
        <DateFormatter
          format={"L LT"}
          value={rowData["customerAccountUpdateDate"]}
        />
      ),
      getExportValueFn: (rowData) =>
        rowData["customerAccountUpdateDate"]
          ? moment(rowData["customerAccountUpdateDate"]).format("L LT")
          : "",
    },
    {
      field: "customerAccountUpdateTypeDescription",
      title: labelPrefix + "description",
      default: true,
    },
    {
      field: "customerAccountUpdateOldValue",
      title: labelPrefix + "oldValue",
      default: true,
    },
    {
      field: "customerAccountUpdateNewValue",
      title: labelPrefix + "newValue",
      default: true,
    },
  ],
};

const StatusHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case STATUS_HISTORY_FAILURE: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default StatusHistoryReducer;
